var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"relative bg-light-blue-700 pb-32 overflow-hidden"}),_c('main',{staticClass:"relative -mt-32"},[_c('div',{staticClass:"max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8"},[_c('div',{staticClass:"bg-white rounded-lg shadow overflow-hidden dark:bg-gray-700"},[_c('div',{staticClass:"divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x h-full"},[_c('aside',{staticClass:"py-6 lg:col-span-3"},[_c('nav',[_c('router-link',{staticClass:"\n                  group\n                  border-l-4\n                  px-3\n                  py-2\n                  flex\n                  items-center\n                  text-sm\n                  font-medium\n                  border-transparent\n                  text-gray-900\n                  hover:bg-gray-50 hover:text-gray-900\n                  dark:hover:bg-gray-400 dark:text-white\n                ",class:{
                  'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                    _vm.$route.meta.title === 'Profile',
                },attrs:{"to":"profile","aria-current":"page"}},[_c('svg',{staticClass:"flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500",class:{ 'text-red-500 group-hover:text-red-500': _vm.$route.meta.title === 'Profile' },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Profile ")])]),_c('router-link',{staticClass:"\n                  border-transparent\n                  text-gray-900\n                  hover:bg-gray-50 hover:text-gray-900\n                  dark:hover:bg-gray-400 dark:text-white\n                  group\n                  mt-1\n                  border-l-4\n                  px-3\n                  py-2\n                  flex\n                  items-center\n                  text-sm\n                  font-medium\n                ",class:{
                  'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                    _vm.$route.meta.title === 'Subscription',
                },attrs:{"to":"subscription"}},[_c('svg',{staticClass:"text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6",class:{ 'text-red-500 group-hover:text-red-500': _vm.$route.meta.title === 'Subscription' },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Subscription ")])]),(_vm.$can('update', 'tenant'))?_c('router-link',{staticClass:"\n                  border-transparent\n                  text-gray-900\n                  hover:bg-gray-50 hover:text-gray-900\n                  dark:hover:bg-gray-400 dark:text-white\n                  group\n                  mt-1\n                  border-l-4\n                  px-3\n                  py-2\n                  flex\n                  items-center\n                  text-sm\n                  font-medium\n                ",class:{
                  'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                    _vm.$route.meta.title === 'Billing',
                },attrs:{"to":"billing"}},[_c('svg',{staticClass:"text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6",class:{ 'text-red-500 group-hover:text-red-500': _vm.$route.meta.title === 'Billing' },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Billing ")])]):_vm._e(),(_vm.$can('update', 'tenant'))?_c('router-link',{staticClass:"\n                  border-transparent\n                  text-gray-900\n                  hover:bg-gray-50 hover:text-gray-900\n                  dark:hover:bg-gray-400 dark:text-white\n                  group\n                  mt-1\n                  border-l-4\n                  px-3\n                  py-2\n                  flex\n                  items-center\n                  text-sm\n                  font-medium\n                ",class:{
                  'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4': _vm.$route.meta.title === 'Team',
                },attrs:{"to":"team"}},[_c('svg',{staticClass:"text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6",class:{ 'text-red-500 group-hover:text-red-500': _vm.$route.meta.title === 'Team' },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Team ")])]):_vm._e()],1)]),_c('div',{staticClass:"divide-y divide-gray-200 lg:col-span-9"},[_c('div',{staticClass:"py-6 px-4 sm:p-6 lg:pb-8"},[_c('div',{staticClass:"mt-6 flex flex-col flex-row h-full"},[_c('div',{staticClass:"flex-grow space-y-6"},[_c('router-view')],1)])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }