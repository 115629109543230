<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Account", "active": true }
       ],
    "title": "Account",
    "resource": "tenant",
    "action": "manage"
  }
}
</route>
<template>
  <div>
    <div class="relative bg-light-blue-700 pb-32 overflow-hidden">
      <!-- On: "bg-light-blue-900", Off: "bg-transparent" -->

      <!-- On: "bottom-0", Off: "inset-y-0" -->
    </div>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden dark:bg-gray-700">
          <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x h-full">
            <aside class="py-6 lg:col-span-3">
              <nav>
                <!-- Current: "bg-red-50 border-red-500 text-red-700 hover:bg-red-50 hover:text-red-700", Default: "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900" -->
                <router-link
                  to="profile"
                  class="
                    group
                    border-l-4
                    px-3
                    py-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    border-transparent
                    text-gray-900
                    hover:bg-gray-50 hover:text-gray-900
                    dark:hover:bg-gray-400 dark:text-white
                  "
                  aria-current="page"
                  :class="{
                    'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                      $route.meta.title === 'Profile',
                  }"
                >
                  <!-- Current: "text-red-500 group-hover:text-red-500", Default: "text-gray-400 group-hover:text-gray-500" -->
                  <!-- Heroicon name: outline/user-circle -->
                  <svg
                    :class="{ 'text-red-500 group-hover:text-red-500': $route.meta.title === 'Profile' }"
                    class="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="truncate"> Profile </span>
                </router-link>

                <router-link
                  to="subscription"
                  class="
                    border-transparent
                    text-gray-900
                    hover:bg-gray-50 hover:text-gray-900
                    dark:hover:bg-gray-400 dark:text-white
                    group
                    mt-1
                    border-l-4
                    px-3
                    py-2
                    flex
                    items-center
                    text-sm
                    font-medium
                  "
                  :class="{
                    'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                      $route.meta.title === 'Subscription',
                  }"
                >
                  <!-- Heroicon name: outline/cog -->
                  <svg
                    :class="{ 'text-red-500 group-hover:text-red-500': $route.meta.title === 'Subscription' }"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span class="truncate"> Subscription </span>
                </router-link>

                <router-link
                  v-if="$can('update', 'tenant')"
                  to="billing"
                  class="
                    border-transparent
                    text-gray-900
                    hover:bg-gray-50 hover:text-gray-900
                    dark:hover:bg-gray-400 dark:text-white
                    group
                    mt-1
                    border-l-4
                    px-3
                    py-2
                    flex
                    items-center
                    text-sm
                    font-medium
                  "
                  :class="{
                    'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4':
                      $route.meta.title === 'Billing',
                  }"
                >
                  <!-- Heroicon name: outline/credit-card -->
                  <svg
                    :class="{ 'text-red-500 group-hover:text-red-500': $route.meta.title === 'Billing' }"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                  <span class="truncate"> Billing </span>
                </router-link>

                <router-link
                  v-if="$can('update', 'tenant')"
                  to="team"
                  class="
                    border-transparent
                    text-gray-900
                    hover:bg-gray-50 hover:text-gray-900
                    dark:hover:bg-gray-400 dark:text-white
                    group
                    mt-1
                    border-l-4
                    px-3
                    py-2
                    flex
                    items-center
                    text-sm
                    font-medium
                  "
                  :class="{
                    'bg-red-50 dark:bg-gray-500 border-red-500 text-red-700  hover:text-red-700  border-l-4': $route.meta.title === 'Team',
                  }"
                >
                  <!-- Heroicon name: outline/view-grid-add -->
                  <svg
                    :class="{ 'text-red-500 group-hover:text-red-500': $route.meta.title === 'Team' }"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                    />
                  </svg>
                  <span class="truncate"> Team </span>
                </router-link>
              </nav>
            </aside>

            <!-- Profile section -->
            <div class="divide-y divide-gray-200 lg:col-span-9">
              <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div class="mt-6 flex flex-col flex-row h-full">
                  <div class="flex-grow space-y-6">
                    <router-view />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
// import OrganisationProfileView from '@/modules/organisation/pages/OrganisationProfileView.vue';

export default {
  name: 'Profile',
  components: {
    // OrganisationProfileView,
  },
  layout: 'AppMain',
};
</script>
